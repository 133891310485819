const data = [
    {
        id:1,
        img:'/img-flota/FurgonEco.png',
        tonelaje:'1.700 - 8.000 Kg'
    },
    {
        id:2,
        img:'/img-flota/FurgonEco2.png',
        tonelaje:'1.700 - 8.000 Kg'
    },
    {
        id:3,
        img:'/img-flota/3_4Eco.png',
        tonelaje:'1.700 - 8.000 Kg'
    }
];

export default data;