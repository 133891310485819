import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Instagram from '../../Images/RRSS/instagram.png';
import Facebook from '../../Images/RRSS/facebook.png';
import Linkedin from '../../Images/RRSS/linkedin.png';
import Ubicacion from '../../Images/Iconos/marcador-de-posicion.png';
import './navbarRRSS.css';

export function NavbarRRSS() {
  return (
      <Navbar className='navRRSS'>
        <Container>
          <div className='RRSS'>
            <a href="https://maps.app.goo.gl/jkvxxbXqAxtDrfaC7" target="_blank" rel="noopener noreferrer">
              <img
                alt=""
                src={Ubicacion}
                width="20"
                height="20"
                className=""
              />
            </a>
            <a href="https://cl.linkedin.com/company/chile-insumos-mym" target="_blank" rel="noopener noreferrer">
              <img
                alt=""
                src={Linkedin}
                width="20"
                height="20"
                className=""
              />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100068013324396&locale=es_LA" target="_blank" rel="noopener noreferrer">
              <img
                alt=""
                src={Facebook}
                width="20"
                height="20"
                className=""
              />
            </a>
            <a href="https://www.instagram.com/maslog.cl/" target="_blank" rel="noopener noreferrer">
              <img
                alt=""
                src={Instagram}
                width="20"
                height="20"
                className=""
              />
            </a>
            </div>
        </Container>
      </Navbar>
  );
}
