const dataM = [
    {
        id: 1,
        img: '/img-nosotros/MASLOG.png',
        nombre: 'Maslog',
        cargo: 'Jefe de Diversión',
        text:'Encargado de mantener el buen ambiente laboral, y de entregar cariño a todos los trabajadores de la empresa.',
    },
    {
        id: 2,
        img: '/img-nosotros/CUCA.png',
        nombre: 'Kuka',
        cargo: 'Jefa de Seguridad',
        text:'Encargada de mantener la seguridad de la empresa, y de mantener el orden en las oficinas.',
    }
];

export default dataM;