import React from 'react';
import Whsapp from '../Images/RRSS/whatsapp-v2.png';
import './whatsapp.css';

const WhatsAppIcon = () => {

  return (
    <a href="https://wa.me/56979188844" target="_blank" rel="noopener noreferrer">
            <img src={Whsapp} className="whatsapp-button" alt="Icono" width="55" height="55"/>
    </a>
  );
};

export default WhatsAppIcon;
