import React from 'react';
import './form.css';
import { Accordion, Container, Row } from 'react-bootstrap';
import Form1 from '../Forms/form1';

export const FormsP = () => {
    return (
        <Container className="formP">
            <Row className='row1'>
                <div className='titulo'>FORMULARIOS</div>
            </Row>
            <Row className='row2'>
                <Accordion className='accor1'>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className='accor1-tit'><strong>¡Trabaja con Nosotros! </strong>
                                - Rellena este formulario y pronto nos contactaremos contigo.
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form1 />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion className='accor2'>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <div className='accor2-tit'><strong>¡Trabaja con Nosotros! </strong>
                                - Rellena este formulario y pronto nos contactaremos contigo.
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Form1 />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
        </Container>
    )
}