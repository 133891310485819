import React from 'react';
import download from '../../Images/Iconos/descargas.png';

class PdfDownloader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            desktopPdf: props.desktopPdf,
            mobilePdf: props.mobilePdf,
            currentPdf: props.desktopPdf
        };
    }

    updatePdfLink = () => {
        if (window.innerWidth <= 600) {
        this.setState({ currentPdf: this.state.mobilePdf });
        } else {
        this.setState({ currentPdf: this.state.desktopPdf });
        }
    }

    componentDidMount() {
        this.updatePdfLink();
        window.addEventListener('resize', this.updatePdfLink);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updatePdfLink);
    }

    render() {
        return (
            <a className="pdfD" href={this.state.currentPdf} download={this.props.linkTextDownload}>
                {this.props.linkText} 
                <img src={download} alt="" width={50} height={50} style={{paddingLeft:5}}/>
            </a>
        );
    }
}

export default PdfDownloader;