import React from 'react';
import { Form, Button } from 'react-bootstrap';
import './form1.css';

class Form1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      reason: '',
      formSubmitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('entry.1980688865', this.state.name); // Reemplaza 123456789 con el ID de tu campo
    formData.append('entry.431716102', this.state.email); // Reemplaza 987654321 con el ID de tu campo
    formData.append('entry.1796790520', this.state.phone); // Reemplaza 246813579 con el ID de tu campo
    formData.append('entry.1979652244', this.state.reason); // Reemplaza 135792468 con el ID de tu campo

    fetch('https://docs.google.com/forms/d/e/1FAIpQLSfk6FEhKNlgQpcrj84IHweu5C3PJIPF_UZ59sbXLZmSVlnhGw/formResponse', {
      method: 'POST',
      body: formData,
      mode: 'no-cors'
    }).then(response => {
      console.log(response);
      this.setState({ formSubmitted: true }); // Actualiza la propiedad
    })
      .catch(error => console.log(error));
  }

  render() {
    if (this.state.formSubmitted) {
      return <div className='enviado'>Enviado correctamente</div>;
    } else {
      return (
        <div className='Form1'>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label className='label'>Nombre</Form.Label>
              <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label className='label'>Email</Form.Label>
              <Form.Control type="email" name="email" value={this.state.email} onChange={this.handleChange} />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label className='label'>Teléfono</Form.Label>
              <Form.Control type="tel" name="phone" value={this.state.phone} onChange={this.handleChange} />
            </Form.Group>

            <Form.Group controlId="formReason">
              <Form.Label className='label'>Motivo</Form.Label>
              <Form.Control as="textarea" name="reason" value={this.state.reason} onChange={this.handleChange} />
            </Form.Group>

            <Button variant="outline-warning" type="submit">
              Enviar
            </Button>
          </Form>
        </div>
      );
    }
  }
}

export default Form1;