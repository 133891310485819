import React from 'react';
import { Navbar, Container, Button, Row, Col } from 'react-bootstrap';
import Logo from "../../Images/footer.png";
import Instagram from '../../Images/RRSS/instagram-w.png';
import Facebook from '../../Images/RRSS/facebook-w.png';
import Linkedin from '../../Images/RRSS/linkedin-w.png';
import './footerP.css';


export function FooterP() {
  return (
    <div className="FooterP" expand="lg" >
      <Container >
        <Row>
          <Navbar.Brand class="logo" href="/">
            <img alt='' src={Logo} width="252" height="52.5" className="logo" />
          </Navbar.Brand>
        </Row>
        <Row className='row2f'>
          <Col xs={4} className="direccion">
            <div >
              <div className='titulo'>Santiago</div>
              <a className="links" href="https://maps.app.goo.gl/jkvxxbXqAxtDrfaC7" target="_blank" rel="noopener noreferrer">
                <div >Avenida Santa Clara 301, OF 2805, Huechueraba</div>
              </a>
              <a className="links" href="tel:>+56979188844" target="_blank" rel="noopener noreferrer">
                <div >+569 7918 8844</div>
              </a>
              <a className="links" href="mailto:contacto@maslog.cl" target="_blank" rel="noopener noreferrer">
                <div >contacto@maslog.cl</div>
              </a>
            </div>
          </Col>
          <Col>
            <div className="contenido">
              <div className='titulo'>Contenido</div>
              <a className="links" href="/servicio" >
                <div >Servicio</div>
              </a>
              <a className="links" href="/flota">
                <div >Flota</div>
              </a>
              <a className="links" href="/form">
                <div >Formularios</div>
              </a>
            </div>
          </Col>
          <Col>
            <div className="compania">
              <div className='titulo'>Compañía</div>
              <a className="links" href="/nosotros">
                <div >Sobre Nosotros</div>
              </a>
              <a className="links" href="/#certificados">
                <div >Certificados</div>
              </a>
              <a className="links" href="/contacto">
                <div >Contactanos</div>
              </a>

            </div>
          </Col>
          <Col className='btn'>
            <a href="/contacto">
              <Button variant="outline-warning" >
                <div>Cotiza con Nosotros</div>
              </Button>
            </a>
          </Col>
        </Row>
        <Row className='row3f'>
          <div className="icon-row">
            <a className='icon-rrss' href="https://cl.linkedin.com/company/chile-insumos-mym" target="_blank" rel="noopener noreferrer">
              <img alt="" src={Linkedin} width="20" height="20" className="" />
            </a>
            <a className='icon-rrss' href="https://www.facebook.com/profile.php?id=100068013324396&locale=es_LA" target="_blank" rel="noopener noreferrer">
              <img alt="" src={Facebook} width="20" height="20" className="" />
            </a>
            <a className='icon-rrss' href="https://www.instagram.com/maslog.cl/" target="_blank" rel="noopener noreferrer">
              <img alt="" src={Instagram} width="20" height="20" className="" />
            </a>
          </div>
        </Row>
        <Row className='row4f'>
          <div>
            © 2021 MASLOG. Todos los derechos reservados.
          </div>
        </Row>
      </Container>
    </div>
  )
};