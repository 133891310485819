import React from 'react';
import { NavbarP } from './Components/navbar/navbarP';
import { NavbarRRSS } from './Components/navbar/navbarRRSS';
import { Pages } from './Components/pages';
import { FooterP } from './Components/footer/footerP';
import { BrowserRouter as Router } from "react-router-dom";
import WhatsAppIcon from './Components/whatsapp';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
  return (
    <Router>
      <NavbarRRSS />
      <NavbarP />
      <Pages />
      <div className="whatsapp-icon-container">
        <WhatsAppIcon />
      </div>
      <FooterP />
    </Router>
  );
}

export default App;
