import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import data from './data-nosotros';
import dataM from './data-mascotas';
import Web from '../../Images/Nosotros/Web.png';
import WebM from '../../Images/Nosotros/Web-m.png';
import correo from '../../Images/RRSS/correo-w.png';
import linkedin from '../../Images/RRSS/linkedin-w.png';
import './nosotros.css';

export const Nosotros = () => {
    return (
        <Container className="nosotros">
            <Row className='row1'>
                <img className="img-desktop" src={Web} alt="" />
                <img className="img-mobile" src={WebM} alt="" />
            </Row>
            <Row className='row2'>
                <Col className='row21'>
                    <div className='titulo'>MISIÓN</div>
                    <div className='parrafo'>Brindar un servicio de excelencia en la gestión logística con principios de seguridad,
                        innovación tecnológica y optimización de procesos del mercado,
                        permitiendo un flujo óptimo de las soluciones adaptables a las necesidades de nuestros clientes en cualquier momento.</div>
                </Col>
                <Col className='row22'>
                    <div className='titulo'>VISIÓN</div>
                    <div className='parrafo'>Ser líderes y consolidarnos en la industria logística en Chile,
                        bajo el uso de metodologías y gestiones integrales con el fin de expandirnos internacionalmente,
                        llevado de la mano de un trabajo profesional y ético,
                        junto con una responsabilidad social empresarial y la sustentabilidad como base principal.</div>
                </Col>
            </Row>
            <Row className='row3'>
                {data.map((item) => (
                    <Col className="col1" md={5}>
                        <Card key={item.id} className="card" style={{ width: '18rem' }}>
                            <Card.Img className="img-card" variant="top" src={item.img} />
                            <Card.Body>
                                <Card.Title className='nombre'>{item.nombre}<br />{item.apellido}</Card.Title>
                                <hr className="linea" />
                                <Card.Title className='cargo'>{item.cargo}</Card.Title>
                                <Card.Text className='txt'>{item.text}</Card.Text>
                                <Card.Text className='txt2'>
                                    <a href={item.correo} target="_blank" rel="noopener noreferrer">
                                        <img className='ico' src={correo} alt='' border='0' />
                                    </a>
                                    <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
                                        <img className='ico' src={linkedin} alt='' border='0' />
                                    </a>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row className='row3'>
                {dataM.map((item) => (
                    <Col className="col1" md={5}>
                        <Card key={item.id} className="card" style={{ width: '18rem' }}>
                            <Card.Img className="img-card" variant="top" src={item.img} />
                            <Card.Body>
                                <Card.Title className='nombre'>{item.nombre}</Card.Title>
                                <hr className="linea" />
                                <Card.Title className='cargo'>{item.cargo}</Card.Title>
                                <Card.Text className='txt'>{item.text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            
        </Container>
    )
}