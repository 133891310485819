import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './Home/home';
import { Nosotros } from './Nosotros/nosotros';
import { Servicio } from './Servicio/servicio';
import { Contacto } from './Contacto/contacto';
import { Flota } from './Flota/flota';
import { FormsP } from './Formas/form';


export const Pages = () => {
    return (
        <Routes>
            <Route path="" element={<Home />} />
            <Route path="nosotros" element={<Nosotros />} />
            <Route path="servicio" element={<Servicio />} />
            <Route path="contacto" element={<Contacto />} />
            <Route path="flota" element={<Flota />} />
            <Route path="form" element={<FormsP/>} />
        </Routes>
    )
}