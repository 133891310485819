import React from 'react';
import './contacto.css';
import { Container, Row, Col } from 'react-bootstrap';
import Web from '../../Images/Contacto/Web.png';
import WebM from '../../Images/Contacto/Web-m.png';
import Whatsapp from '../../Images/RRSS/whatsapp.png';
import Ubicacion from '../../Images/Iconos/alfiler.png';
import Correo from '../../Images/RRSS/correo.png';

export const Contacto = () => {
    return (
        <Container className='contacto'>
            <Row className='row1'>
                <img className="img-desktop" src={Web} alt="" />
                <img className="img-mobile" src={WebM} alt="" />
            </Row>
            <Row className='row3'>
                <Col className='col1'>
                    <a className='txt' href="https://wa.me/56979188844" target="_blank" rel="noopener noreferrer">
                        <img className='ico' src={Whatsapp} alt='' href="" />
                        <div>+569 7918 8844</div>
                    </a>
                </Col>
                <Col className='col2'>

                    <a className="txt" href="https://maps.app.goo.gl/jkvxxbXqAxtDrfaC7" target="_blank" rel="noopener noreferrer">
                        <img className='ico' src={Ubicacion} alt='' />
                        <div >Avenida Santa Clara 301,<br />OF 2805, Huechueraba</div>
                    </a>
                </Col>
                <Col className='col3'>
                    <a className="txt" href="mailto:contacto@maslog.cl" target="_blank" rel="noopener noreferrer">
                        <img className='ico' src={Correo} alt='' />
                        <div>contacto@maslog.cl</div>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}