const data = [
    {
        id:1,
        img:'/img-flota/Ciguena.png',
        tonelaje:'Transporte de Vehículos'
    },
    {
        id:2,
        img:'/img-flota/Botellero.png',
        tonelaje:'Equipo Botellero'
    },
    {
        id:3,
        img:'/img-flota/Batea.png',
        tonelaje:'Equipo Batea'
    },
    {
        id:4,
        img:'/img-flota/Cisterna.png',
        tonelaje:'Equipo Cisterna'
    },
    {
        id:5,
        img:'/img-flota/Rebajado.png',
        tonelaje:'Equipo Rebajado'

    },
];

export default data;