const data = [
    {
        id:1,
        img:'/img-flota/Paquetera.png',
        tonelaje:'18 - 28 Ton'
    },
    {
        id:2,
        img:'/img-flota/Chassis.png',
        tonelaje:'18 - 28 Ton'
    },
    {
        id:3,
        img:'/img-flota/Plana.png',
        tonelaje:'18 - 28 Ton'
    },
    {
        id:4,
        img:'/img-flota/Granelero.png',
        tonelaje:'18 - 28 Ton'
    },
    
    {
        id:6,
        img:'/img-flota/Refrigerado.png',
        tonelaje:'18 - 28 Ton'
    },
    {
        id:7,
        img:'/img-flota/Sider.png',
        tonelaje:'18 - 28 Ton'
    }
];

export default data;