import React from 'react';
import './flota.css';
import { Container, Row, Col } from 'react-bootstrap';
import Web from '../../Images/Flota/Web.png';
import WebM from '../../Images/Flota/Web-m.png';
import data from './data-flota';
import Furgones from './data1F';
import CamionesAlto from './data2C';
import Maquinaria from './data3M';
import Especiales from './data4E';
import Eco from './data-Eco';
import Form1 from "../Forms/form1";
import IcoEco from '../../Images/Logos/eco.png';


export const Flota = () => {
    return (
        <Container className="flota">
            <Row className='row1'>
                <img className="img-desktop" src={Web} alt="" />
                <img className="img-mobile" src={WebM} alt="" />
            </Row>
            <Row className='row2'>
                <Row>
                    <Row>
                        <div className='categoria'>Furgones</div>
                    </Row>
                    <Row>
                        {Furgones.map((item) => (
                            <Col md={4}>
                                <div class="box">
                                    <figure>
                                        <img class="img" src={item.img} alt='' />
                                        <div className="contenido">
                                            <div className="txt">
                                                Transporte de<br /> <strong>{item.tonelaje}</strong>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <div className='categoriaE'>
                            Electricos
                            <img className='icoEco' src={IcoEco} alt='' />
                        </div>
                    </Row>
                    <Row>
                        {Eco.map((item) => (
                            <Col md={4}>
                                <div class="boxE">
                                    <figure>
                                        <img class="img" src={item.img} alt='' />
                                        <div className="contenido">
                                            <div className="txt">
                                                Transporte de<br /> <strong>{item.tonelaje}</strong>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <div className='categoria'>Camiones</div>
                    </Row>
                    <Row>
                        {data.map((item) => (
                            <Col md={4}>
                                <div class="box">
                                    <figure>
                                        <img class="img" src={item.img} alt='' />
                                        <div className="contenido">
                                            <div className="txt">
                                                Transporte de<br /> <strong>{item.tonelaje}</strong>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <div className='categoria'>Camiones Alto Tonelaje</div>
                    </Row>
                    <Row>
                        {CamionesAlto.map((item) => (
                            <Col md={4}>
                                <div class="box">
                                    <figure>
                                        <img class="img" src={item.img} alt='' />
                                        <div className="contenido">
                                            <div className="txt">
                                                Transporte de<br /> <strong>{item.tonelaje}</strong>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <div className='categoria'>Maquinaría</div>
                    </Row>
                    <Row>
                        {Maquinaria.map((item) => (
                            <Col md={4}>
                                <div class="box">
                                    <figure>
                                        <img class="img" src={item.img} alt='' />
                                        <div className="contenido">
                                            <div className="txt">
                                                <strong>{item.tonelaje}</strong>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <div className='categoria'>Trabajos Especiales</div>
                    </Row>
                    <Row>
                        {Especiales.map((item) => (
                            <Col md={4}>
                                <div class="box">
                                    <figure>
                                        <img class="img" src={item.img} alt='' />
                                        <div className="contenido">
                                            <div className="txt">
                                                <strong>{item.tonelaje}</strong>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row>
            </Row>
            <Row className='row3'>
                <div className="titulo">¡TRABAJA CON NOSOTROS!</div>
                <Form1 />
            </Row>
        </Container>
    )
}