import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import { Row, Col, Button, Container } from "react-bootstrap";
import Web from "../../Images/HomeIMG/Home.png";
import WebM from "../../Images/HomeIMG/Home.png";
import NosotrosHomer from "../../Images/HomeIMG/NUESTRA-LABOR.jpg";
import trasnspCarga from "../../Images/HomeIMG/Web-07.png";
import arriendomaq from "../../Images/HomeIMG/Web-10.png";
import distribucion from "../../Images/HomeIMG/Web-09.png";
import almacenaje from "../../Images/HomeIMG/Web-08.png";
import Norte from "../../Images/HomeIMG/Norte.png";
import NorteM from "../../Images/HomeIMG/Norte-m.png";
import Centro from "../../Images/HomeIMG/Centro.png";
import CentroM from "../../Images/HomeIMG/Centro-m.png";
import Sur from "../../Images/HomeIMG/Sur.png";
import SurM from "../../Images/HomeIMG/Sur-m.png";
import flota from "../../Images/HomeIMG/Flota.png";
import Form1 from "../Forms/form1";
import doc from "../../Images/Logos/Documento.png";
import sicep from "../../Images/Logos/SICEP.png";
import PdfDownloader from "../Download/pdf";
import Snt2023 from "../../Images/HomeIMG/PANAM.png";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import { useLocation } from "react-router-dom";
// import Modd from "../../Images/HomeIMG/modal.png";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export const Home = () => {

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      const elementId = location.hash.substring(1);
      const element = document.getElementById(elementId);
      if (element) element.scrollIntoView();
    }, 0);
  }, [location]);

  // const [showModal, setShowModal] = useState(true);
  // const handleClose = () => setShowModal(false);
  
  function importAll(r) {
    return r.keys().map(r);
  }

  const images1 = importAll(require.context('../../Images/Empresas', false, /\.(png|jpe?g|svg)$/));
  const images2 = importAll(require.context('../../Images/Sant2023', false, /\.(png|jpe?g|svg)$/));

  return (

    <Container className="home">
      {/* <Modal className="modal1" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><div className="tit">¡Seguimos mejorando!</div></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <img className='modalimg' src={Modd} alt="" />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
      </Modal> */}
      <Row className='row1'>
        <img className="img-desktop" src={Web} alt="" />
        <img className="img-mobile" src={WebM} alt="" />
      </Row>
      <Row className='row2'>
        <Carousel className="carrusel-clientes" responsive={responsive} autoPlay={true} autoPlaySpeed={1500} infinite={true}
          showDots={false} arrows={false} renderButtonGroupOutside={false}>
          {images1.map((image, index) => (
            <div className="imgC" key={index}>
              <img alt="" src={image} className="imgC" />
            </div>
          ))}
        </Carousel>
      </Row>
      <Row className='row3'>
        <div className="box">
          <Col lg={4}>
            <img className="img-row3" alt="" src={NosotrosHomer} />
          </Col>
          <Col lg={8}>
            <div className="text-row3" >Nos dedicamos a ofrecer <strong>soluciones logísticas
              óptimas y adaptables</strong> que satisfagan las necesidades de nuestros clientes en todo momento, colocando en primer plano la
              <strong > seguridad, innovación y tecnología.</strong>
            </div>
          </Col>
        </div>
      </Row>
      <Row className="row4">
        <Col className="titulo">Servicios</Col>
        <Col>
          <div class="box">
            <figure>
              <img class="img" src={trasnspCarga} width='200' alt='' />
              <div className="contenido">
                <div className="txt">
                  Transporte nacional al consolidar su carga con múltiples destinatarios en Chile de forma SEGURA.
                </div>
                <Link to='/servicio#trasnspCarga'>
                  <Button variant="outline-warning">
                    Ver Más
                  </Button>
                </Link>
              </div>
            </figure>
          </div>
        </Col>
        <Col>
          <div class="box">
            <figure>
              <img class="img" src={arriendomaq} width='200' alt='' />
              <div className="contenido">
                <div className="txt">
                  Renta de maquinarias para dar soluciones específicas de forma inmediata.
                </div>
                <Link to='/servicio#arriendomaq'>
                  <Button variant="outline-warning">
                    Ver Más
                  </Button>
                </Link>
              </div>

            </figure>
          </div>
        </Col>
        <Col>
          <div class="box">
            <figure>
              <img class="img" src={distribucion} width='200' alt='' />
              <div className="contenido">
                <div className="txt">
                  Nos especializamos en la gestión de primera y última milla.
                </div>
                <Link to='/servicio#distribucion'>
                  <Button variant="outline-warning">
                    Ver Más
                  </Button>
                </Link>
              </div>

            </figure>
          </div>
        </Col>
        <Col>
          <div class="box">
            <figure>
              <img class="img" src={almacenaje} width='200' alt='' />
              <div className="contenido">
                <div className="txt">
                  Tenemos bodegas preparadas para llevar a cabo las actividades de almacenamiento.
                </div>
                <Link to='/servicio#almacenaje'>
                  <Button variant="outline-warning">
                    Ver Más
                  </Button>
                </Link>
              </div>

            </figure>
          </div>
        </Col>
      </Row>
      <Row className='row5'>
        <Row className="row51">
          <div className="titulo">Presencia</div>
        </Row>
        <Row className="row52">
          <Row>
            <div class="box">
              <figure>
                <img class="img" src={Norte} alt='' />
                <img class="img-m" src={NorteM} alt='' />
                <div className="contenido">
                  <div className="txt">
                    Nuestro equipo lleva a cabo operaciones de traslado de materiales e insumos de minería manteniendo un compromiso y soluciones oportunas en ciudades como Calama, Antofagasta, y más. Además, se mantiene una constante seguridad y evaluaciones certificadas con respaldo SICEP.
                  </div>
                  <div className="txt-m">
                    Traslado de materiales e insumos de minería manteniendo un compromiso y soluciones oportunas.
                  </div>
                </div>
              </figure>
            </div>
          </Row>
          <Row>
            <div class="box">
              <figure>
                <img class="img" src={Centro} alt='' />
                <img class="img-m" src={CentroM} alt='' />
                <div className="contenido">
                  <div className="txt">
                    MASLOG actúa con grandes empresas en la distribución en carga seca y fría de la Región Metropolitana, Provincias y Litoral Central, entregando un trabajo eficaz dentro de la cadena de suministros en tiempos optimizados para el cliente final. También cabe destacar la funcionalidad de nuestros movimientos extraportuarios desde los principales puertos en dirección de alcance nacional.
                  </div>
                  <div className="txt-m">
                    Actuamos con grandes empresas en distribución de alimentos, cumpliendo un rol importante dentro de la cadena de suministros.
                  </div>
                </div>
              </figure>
            </div>
          </Row>
          <Row>
            <div class="box">
              <figure>
                <img class="img" src={Sur} alt='' />
                <img class="img-m" src={SurM} alt='' />
                <div className="contenido">
                  <div className="txt">
                    Llevamos operaciones en ciudades como Temuco, Concepción, Puerto Montt, y más. Movimientos de carga frágil y de alto valor en tramos extensos hasta zonas australes, destacando por la gestión de contactos de traslados terrestres y marítimos.
                  </div>
                  <div className="txt-m">
                    Operaciones activas con grandes marcas en ciudades como Temuco, Concepción y más, destacando por la gestión de contactos de traslado terrestres y marítimos.
                  </div>
                </div>
              </figure>
            </div>
          </Row>
        </Row>

      </Row>
      <Row className='row6'>
        <div className="row61">
          <img alt="" src={flota} />
          <Button href="/flota" variant="outline-warning">
            <div className="txt">
              Ver Más
            </div>
          </Button>
        </div>
      </Row>
      <Row className='row8'>
        <div className="titulo">
          <Row>
            <div className="tit">Experiencia con: </div>
          </Row>
          <Row>
            <img className='imgTit' src={Snt2023} alt='' />
          </Row>
        </div>
        <Carousel className="carrusel-clientes" responsive={responsive} autoPlay={true} autoPlaySpeed={1000} infinite={true}
          showDots={false} arrows={false} renderButtonGroupOutside={false}>
          {images2.map((image, index) => (
            <div className="imgC" key={index}>
              <img alt="" src={image} className="imgC" />
            </div>
          ))}
        </Carousel>
      </Row>
      <Row className='row7'>
        <Col id='certificados' className="certificados">
          <Row id="certificados">
            <div className="titulo">Certificados</div>
            <Row className="cer1">
              <img className="img1" alt="" src={doc} />
              <PdfDownloader
                linkText="Brochure"
                linkTextDownload="Brochure.pdf"
                desktopPdf="/pdf-download/BrochurePC.pdf"
                mobilePdf="/pdf-download/BrochureCEL.pdf"
              />
            </Row>
            <Row className="cer2">
              <img className="img1" alt="" src={doc} />
              <PdfDownloader
                linkText="Póliza de Seguro"
                linkTextDownload="PolizaSeguroMaslog.pdf"
                desktopPdf="/pdf-download/PolizaSeguroMaslog.pdf"
                mobilePdf="/pdf-download/PolizaSeguroMaslog.pdf"
              />
            </Row>
            <Row className="cer3">
              <img className="img1" alt="" src={doc} />
              <PdfDownloader
                linkText="Política de Sist Gestión"
                linkTextDownload="POLITICASISTEMADEGESTIÓN MASLOG.pdf"
                desktopPdf="/pdf-download/POLITICASISTEMADEGESTIÓN.pdf"
                mobilePdf="/pdf-download/POLITICASISTEMADEGESTIÓN.pdf"
              />
            </Row>
            <Row className="cer3">
              <img className="img2" alt="" src={sicep} />
              <PdfDownloader
                linkText="Sicep"
                linkTextDownload="sicep maslog.pdf"
                desktopPdf="/pdf-download/Sicep.pdf"
                mobilePdf="/pdf-download/Sicep.pdf"
              />
            </Row>
          </Row>
        </Col>
        <Col className="formulario">
          <div className="titulo">Contáctanos</div>
          <Form1 />
        </Col>
      </Row>
    </Container>
  );
}