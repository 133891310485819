import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import Logo from "../../Images/Logo normal.png";
import Ruteo from "../../Images/Iconos/ruta-m-24.png";
import './navbarP.css';


export function NavbarP() {
  return (
    <Navbar className="NavbarP" expand="lg" >
      <Container>
        <Navbar.Brand href="/">
          <img alt="" src={Logo} width="252" height="52.5" className="logo" />
        </Navbar.Brand>
        <a href="https://app.track-pod.com/Route/Authorization/Login/" target="_blank" rel="noopener noreferrer">
          <Button variant="outline-warning" className="responsive-button">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Ruteo} alt="Icono" style={{ marginLeft: '5px' }} />
            </div>
          </Button>
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="responsive-navbar-nav" id="basic-navbar-nav">
          <Nav className="sub-nav">
            <Nav.Link className='items' href="/">Inicio</Nav.Link>
            <Nav.Link className='items' href="/servicio">Servicio</Nav.Link>
            {/* <Nav.Link className='items' href="/">Tarifa</Nav.Link> */}
            <Nav.Link className='items' href="/flota">Flota</Nav.Link>
            <Nav.Link className='items' href="/nosotros">Nosotros</Nav.Link>
            <Nav.Link className='items' href="/contacto">Contacto</Nav.Link>
          </Nav>
          <a href="https://app.track-pod.com/Route/Authorization/Login/" target="_blank" rel="noopener noreferrer">
            <Button variant="outline-warning">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Ruteo} alt="Icono" style={{ marginLeft: '5px' }} />
                <span>SEGUIMIENTO</span>
              </div>
            </Button>
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
};