const data = [
    {
        id: 1,
        img: '/img-flota/Pluma.png',
        tonelaje:'Grúa Pluma'
    },
    {
        id: 2,
        img: '/img-flota/Grua.png',
        tonelaje:'Grúa Horquilla'
    },
    {
        id: 3,
        img: '/img-flota/AlzaHombre.png',
        tonelaje:'Alza Hombre'
    },
    {
        id: 4,
        img: '/img-flota/SideLifter.png',
        tonelaje:'Side Lifter'
    }
];

export default data;