const data = [
    {
        id: 1,
        img: '/img-flota/Camion3_4.png',
        tonelaje:'1.700 - 18.000 Kg'
    },
    {
        id: 2,
        img: '/img-flota/CamionFrigorifico.png',
        tonelaje:'1.700 - 18.000 Kg'
    },
    {
        id: 3,
        img: '/img-flota/Hidraulica.png',
        tonelaje:'1.700 - 18.000 Kg'
    },
    {
        id: 4,
        img: '/img-flota/CamionPalets.png',
        tonelaje:'1.700 - 18.000 Kg'
    },
    {
        id: 5,
        img: '/img-flota/CamionFrontier.png',
        tonelaje:'1.700 - 18.000 Kg'
    },
    
    
];

export default data;