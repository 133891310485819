const data = [
    {
        id: 1,
        img: '/img-nosotros/Matias-Aviles.png',
        nombre: 'Matías ',
        apellido: 'Avíles',
        cargo: 'Gerente de Operaciones',
        text:'Encargado de planificar, dirigir y coordinar las operaciones de transporte, almacenamiento y distribución. Supervisa eficientemente las actividades logísticas para garantizar la eficacia operativa, la satisfacción del cliente y la alineación con los objetivos estratégicos de la empresa.',
        linkedin: 'https://www.linkedin.com/in/matias-aviles-avenda%C3%B1o-3b2069101/',
        correo: 'mailto:maviles@maslog.cl'
    },
    {
        id: 2,
        img: '/img-nosotros/Pablo-Martinez.png',
        nombre: 'Pablo ',
        apellido: 'Martínez',
        cargo: 'Gerente General',
        text: 'Supervisar la operatividad diaria. Diseñar estrategias y fijar objetivos para el crecimiento. Mantener presupuestos y optimizar gastos. Establecer políticas y procesos. Asegurarse de que los empleados trabajen de forma productiva y se desarrollen profesionalmente.',
        linkedin: 'https://www.linkedin.com/in/pablopama1993/',
        correo: 'mailto:pmartinez@maslog.cl'
    },
    {
        id: 3,
        img: '/img-nosotros/Javier-Calfiman.png',
        nombre: 'Javier ',
        apellido: 'Calfiman',
        cargo: 'Jefe de Operaciones',
        text: 'Dirige y controla que los procesos dentro de todas las operaciones activas se lleven a cabo en terreno, con la finalidad de mantener un funcionamiento eficiente dentro de las actividades operativas y administrativas. Además, trabaja directamente en traspaso de información con gerencia.',
        linkedin: 'https://www.linkedin.com/in/javier-calfiman-urra-98289612b/',
        correo: 'mailto:Jcalfiman@maslog.cl'
    },
    {
        id: 4,
        img: '/img-nosotros/Carla-Cornejo.png',
        nombre: 'Carla ',
        apellido: 'Cornejo',
        cargo: 'Jefa de Recursos Humanos',
        text: 'Planificar, dirigir y coordinar las actividades del personal y las relaciones laborales, así como las políticas y prácticas de una compañía. Reclutar y seleccionar personal, sabiendo concretamente cuáles son las carencias de talento, y qué perfil de persona podrá encajar en cada puesto de trabajo. Reporta al gerente general.',
        linkedin: 'https://www.linkedin.com/in/carlacornejot/',
        correo: 'mailto:ccornejo@maslog.cl'
    }
];

export default data;