const data = [
    {
        id:1,
        img:'/img-flota/Partner.png',
        tonelaje:'800 -1.700 Kg'
    },
    {
        id:2,
        img:'/img-flota/N400.png',
        tonelaje:'800 -1.700 Kg'
    },
    {
        id:3,
        img:'/img-flota/Boxer.png',
        tonelaje:'800 -1.700 Kg'
    }
];


export default data;