import React from 'react';
import './servicio.css'
import { Container, Row, Card } from 'react-bootstrap';
import Web from '../../Images/Servicio/Web.png';
import WebM from '../../Images/Servicio/Web-m.png';
import TranspCarga from '../../Images/Servicio/Transdecarga.png';
import TranspCargaMobile from '../../Images/Servicio/Transdecarga-m.png';
import Almacenaje from '../../Images/Servicio/Almacenaje.png';
import AlmacenajeMobile from '../../Images/Servicio/Almacenaje-m.png';
import Distribucion from '../../Images/Servicio/Distri.png';
import DistribucionMobile from '../../Images/Servicio/Distri-m.png';
import ArriendoaMaq from '../../Images/Servicio/Arriendodemaquinaria.png';
import ArriendoaMaqMobile from '../../Images/Servicio/Arriendodemaquinaria-m.png';
import Doc from '../../Images/Logos/Documento-m.png';
import PdfDownloader from '../Download/pdf';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Servicio = () => {
    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            const elementId = location.hash.substring(1);
            const element = document.getElementById(elementId);
            if (element) element.scrollIntoView();
        }, 0);
    }, [location]);

    return (
        <Container className="servicio">
            <Row className="row1">
                <img className="img-desktop" src={Web} alt="" />
                <img className="img-mobile" src={WebM} alt="" />
            </Row>
            <Row className="row2">
                <Card id='trasnspCarga' className="card">
                    <Card.Img className="img-card-desktop" variant="top" src={TranspCarga} />
                    <Card.Img className="img-card-mobile" variant="top" src={TranspCargaMobile} />
                    <Card.Body>
                        <Card.Title className='especificacion1'>
                            Servicio<strong> dedicado </strong>y
                            <strong> exclusivo </strong>, brinda
                            <strong> seguridad en <br />movimiento </strong>
                            con alcance de traslado
                            <strong> nacional e internacional </strong><br />para las industrias.
                        </Card.Title>
                        <Card.Title className='especificacion2'>
                            <ul className="puntos">
                                <li>Transporte en carga general</li>
                                <li>Carga Liviana</li>
                                <li>Contenedores</li>
                                <li>Carga Peligrosa</li>
                            </ul>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card id='almacenaje' className="card">
                    <Card.Img className="img-card-desktop" variant="top" src={Almacenaje} />
                    <Card.Img className="img-card-mobile" variant="top" src={AlmacenajeMobile} />
                    <Card.Body>
                        <Card.Title className='especificacion1'>
                            La<strong> seguridad </strong>
                            es prioridad en nuestras actividades de
                            <strong> almacenaje <br /> de despacho, manejo
                                de inventario </strong>y
                            <strong> recepción de contenidos.</strong>
                        </Card.Title>
                        <Card.Title className='especificacion2'>
                            <ul className="puntos">
                                <li>Picking</li>
                                <li>Etiquetado</li>
                                <li>Control de inventario</li>
                                <li>Servicios Integrales</li>
                                <li>Seguros y más</li>
                            </ul>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card id='distribucion' className="card">
                    <Card.Img className="img-card-desktop" variant="top" src={Distribucion} />
                    <Card.Img className="img-card-mobile" variant="top" src={DistribucionMobile} />
                    <Card.Body>
                        <Card.Title className='especificacion1'>
                        Simplificamos el<strong> transporte de cargas </strong>
                        hacia cualquier destino<br/> nacional, proporcionando
                        <strong> informes detallados </strong>que brindan total<br/> 
                        visibilidad, además de garantizar la<strong> seguridad y entregas<br/>exitosas </strong>
                        de sus cargas.
                        </Card.Title>
                        <Card.Title className='especificacion2'>
                            <ul className="puntos">
                                <li>Tecnología de rastreo</li>
                                <li>Informes diarios de trazabilidad</li>
                                <li>Retiro y entregas<br />inmediatas</li>
                                <li>Cargas pesadas<br />y ligeras</li>
                            </ul>
                        </Card.Title>
                    </Card.Body>
                </Card>
                <Card id='arriendomaq' className="card">
                    <Card.Img className="img-card-desktop" variant="top" src={ArriendoaMaq} />
                    <Card.Img className="img-card-mobile" variant="top" src={ArriendoaMaqMobile} />
                    <Card.Body>
                        <Card.Title className='especificacion1'>
                            El mantenimiento operativo (
                            <strong>correctivo </strong>y
                            <strong> preventivo</strong>) ofrece un<br />
                            alto estándar de<strong> seguridad. </strong>
                            Servicio disponible con
                            <strong> alcance de<br />industrias nacionales.</strong>
                        </Card.Title>
                        <Card.Title className='especificacion2'>
                            <ul className="puntos">
                                <li>Grúa horquillas</li>
                                <li>Manipulador telescópico</li>
                                <li>Retroexcavadora</li>
                                <li>Side Lifter</li>
                                <li>Alza Hombre</li>
                                <li>Y más...</li>
                            </ul>
                        </Card.Title>
                    </Card.Body>
                </Card>

            </Row>
            <Row className="row3">
                <div className="titulo">Póliza de Seguro</div>
                <div className="parrafo">
                    Nuestra póliza de seguro ofrece coberturas para
                    <strong> protegerte </strong>en
                    <strong> situaciones<br />inesperadas</strong>
                    , garantizando<strong> tu bienestar </strong>
                    y brindándote la mejor<strong> protección.</strong>
                </div>
                <div className='uf'>Hasta 6.000 UF</div>
                <div className="parrafo">
                    Para más información acerca de nuestra póliza seguro,
                    puedes<br /> descargar el siguiente documento.
                </div>

                <div className="descarga">
                    <img className='docico' src={Doc} alt='' typeof='ico' />
                    <PdfDownloader
                        linkText="PÓLIZA DE SEGUROS"
                        linkTextDownload="PolizaSeguroMaslog.pdf"
                        desktopPdf="/pdf-download/PolizaSeguroMaslog.pdf"
                        mobilePdf="/pdf-download/PolizaSeguroMaslog.pdf"
                    />
                </div>
            </Row>
        </Container>
    )
}